'use client';

import { ToastContainer } from 'react-toastify';
import { Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ReactToastifyContainer: React.FC = () => (
    <ToastContainer
        autoClose={3000}
        transition={Bounce}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        draggable
        theme="light"
    />
);
