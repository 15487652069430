'use client';

import { memo } from 'react';
import { GoogleLoginButton } from '../../Inputs/GoogleLoginButton/GoogleLoginButton';
import * as s from './style.css';

type Props = {
    /** Googleログインボタンクリック時のコールバック */
    onGoogleLogin?: () => void;
    /**
     * ログインボタンを無効化するかどうか
     *
     * @default false
     */
    disabled?: boolean;
};

/**
 * 認証が必要な場合のログインページ
 */
export const LoginPage: React.FC<Props> = memo(({ onGoogleLogin, disabled = false }) => (
    <article className={s.container}>
        <div className={s.whiteBox}>
            <h1 className={s.headingText}>このページの閲覧にはログインが必要です。</h1>
            <div className={s.loginButtonBox}>
                <GoogleLoginButton onClick={onGoogleLogin} disabled={disabled} />
            </div>
        </div>
    </article>
));

LoginPage.displayName = 'LoginPage';
