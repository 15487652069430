'use client';

import { breakPoints } from '@/domains/style/models';
import type { BreakPointName } from '@/domains/style/models';

/**
 * 現在のブレークポイントを取得する
 */
export default function getCurrentBreakpoint(event: MediaQueryListEvent): BreakPointName {
    const width = Number.parseInt(event.media.match(/\d+/g)?.[0] ?? '0', 10);

    if (width >= Number.parseInt(breakPoints.lg, 10)) {
        return 'lg';
    }
    if (width >= Number.parseInt(breakPoints.md, 10)) {
        return 'md';
    }
    if (width >= Number.parseInt(breakPoints.sm, 10)) {
        return 'sm';
    }
    return 'xs';
}
