import { Spinner } from '@/components/ui/Graphics/Spinner/Spinner';
import * as s from './style.css';

/**
 * ページのlazyロードのために使う全画面のロード画面
 */
export const FullPageFallback: React.FC = () => (
    <div className={s.root}>
        <Spinner alt="ロード中" />
    </div>
);
