'use client';

import { useEffect, useMemo, useState } from 'react';

import { FullPageFallback } from '@/components/ui/Layout/FullPageFallback/FullPageFallback';
import { breakPointPixels } from '@/domains/style/models';
import BreakpointContext from './BreakpointContext';
import getCurrentBreakpoint from './getCurrentBreakpoint';
import getCurrentBreakpointFromWindow from './getCurrentBreakpointFromWindow';
import type { BreakPointName } from '@/domains/style/models';

interface Props {
    children: React.ReactNode;
}

/**
 * 現在のブレークポイントを監視するプロバイダー
 */
export default function BreakpointProvider({ children }: Props) {
    const [breakpoint, setBreakpoint] = useState<BreakPointName | undefined>(undefined);

    const isMobile = useMemo(
        () => breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md',
        [breakpoint],
    );
    const isPc = useMemo(() => breakpoint === 'lg', [breakpoint]);

    useEffect(() => {
        // 初回描画時、ウィンドウサイズから現在のブレークポイントを計算して設定
        const initialBreakpoint = getCurrentBreakpointFromWindow();
        setBreakpoint(initialBreakpoint);

        const handleBreakpointChange = (event: MediaQueryListEvent) => {
            if (event.matches) {
                setBreakpoint(getCurrentBreakpoint(event));
            }
        };

        /** ブレークポイントのMediaQueryListのリスト */
        const mediaQueryList = {
            xs: window.matchMedia(`(max-width: ${breakPointPixels.sm - 0.01}px)`),
            sm: window.matchMedia(
                `(min-width: ${breakPointPixels.sm + 0.01}px) and (max-width: ${
                    breakPointPixels.md - 0.01
                }px)`,
            ),
            md: window.matchMedia(
                `(min-width: ${breakPointPixels.md}px) and (max-width: ${
                    breakPointPixels.lg - 0.01
                }px)`,
            ),
            lg: window.matchMedia(`(min-width: ${breakPointPixels.lg}px)`),
        } as const satisfies Record<BreakPointName, MediaQueryList>;

        for (const key in mediaQueryList) {
            const mediaQueryListKey = key as BreakPointName;
            if (mediaQueryList[mediaQueryListKey].matches) {
                setBreakpoint(mediaQueryListKey);
            }
            mediaQueryList[mediaQueryListKey].addEventListener('change', handleBreakpointChange);
        }

        return () => {
            for (const key in mediaQueryList) {
                const mediaQueryListKey = key as BreakPointName;
                mediaQueryList[mediaQueryListKey].removeEventListener(
                    'change',
                    handleBreakpointChange,
                );
            }
        };
    }, []);

    // 初回描画時、ブレークポイントが取得できるまでローディングを表示
    if (breakpoint === undefined) {
        return <FullPageFallback />;
    }

    return (
        <BreakpointContext.Provider value={{ breakpoint, isMobile, isPc }}>
            {children}
        </BreakpointContext.Provider>
    );
}
