/** ブレークポイントの定義 */
export const breakPointPixels = {
    xs: 0,
    sm: 390,
    md: 768,
    lg: 1024,
} as const;

/** 呼び出し用のブレークポイント名 */
export type BreakPointName = keyof typeof breakPointPixels;

/** 呼び出し用のブレークポイント */
export const breakPoints = {
    xs: `${breakPointPixels.xs}px`,
    sm: `${breakPointPixels.sm}px`,
    md: `${breakPointPixels.md}px`,
    lg: `${breakPointPixels.lg}px`,
} as const satisfies Record<BreakPointName, string>;

/**
 * メディアクエリの定義
 *
 * @example
 * '@media': {
 *    [mediaQueries.md]: {
 *       display: 'block',
 *   },
 *  }
 *
 */
export const mediaQueries = {
    xs: `screen and (min-width: ${breakPointPixels.xs}px)`,
    sm: `screen and (min-width: ${breakPointPixels.sm}px)`,
    md: `screen and (min-width: ${breakPointPixels.md}px)`,
    lg: `screen and (min-width: ${breakPointPixels.lg}px)`,
} as const satisfies Record<BreakPointName, string>;

/** アプリケーション全体で使用する色リスト */
export const palette = {
    beige: '#E3DCCD',
    black: '#222222',
    blue: '#5195D9',
    danger: '#D91156',
    darkGray: '#BCBCBC',
    darkGreen: '#00BC00',
    gray: '#707070',
    lightBlue: '#CDD6DD',
    lightGray: '#E4E4E4',
    lightGreen: '#F5FF54',
    line: '#00BC00',
    mediumBlue: '#98A6B5',
    mediumGray: '#d5d5d5',
    mintGreen: '#14F4C9',
    orange: '#F7753B',
    paleGray: '#DDDDDD',
    softGray: '#F5F5F4',
    white: '#FFFFFF',
    yellow: '#FFC503',
} as const;

/** グローバルに定義された色名の定義 */
export type PaletteColor = keyof typeof palette;

/** アプリケーション全体で使用するZ-index */
export const zIndex = {
    pageContainer: 1000,
    fofocaMovie: -10,
    globalNav: 2000,
    frame: 3000,
    dialogBackdrop: 4000,
    humbergerButton: 9000,
} as const;
