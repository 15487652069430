import { memo, useMemo } from 'react';

import type { PaletteColor } from '@/domains/style/models';

type Props = {
    /**
     * スピナーの色
     *
     * @default 'gray'
     */
    color?: PaletteColor;
    /**
     * スピナーの大きさ
     *
     * @default 40
     */
    size?: number;
    /**
     * この属性が空の場合は、aria-label属性を付与せず、SVGのrole属性は`presentation`になる。
     * この属性が空でない場合は、aria-label属性の内容として使用され、SVGのrole属性は`img`になる。
     *
     * @default ''
     */
    alt?: string;
};

/**
 * ロード中を表すスピナー
 */
export const Spinner: React.FC<Props> = memo(({ color = 'gray', size = 40, alt = '' }) => {
    const rects = useMemo(
        () =>
            Array.from({ length: 12 }, (_, index) => (
                <rect
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={`rect-${index}`}
                    x="11"
                    y="1"
                    width="2"
                    height="5"
                    opacity={index * 0.05 + 0.14}
                    style={{
                        transform: `rotate(${index * 30}deg)`,
                        transformOrigin: '12px 12px',
                    }}
                />
            )),
        [],
    );

    return (
        <svg
            role={alt === '' ? 'presentation' : 'img'}
            aria-label={alt === '' ? undefined : alt}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={color}
        >
            <g>
                {rects}
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="discrete"
                    dur="0.75s"
                    values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12"
                    repeatCount="indefinite"
                />
            </g>
        </svg>
    );
});

Spinner.displayName = 'Spinner';
