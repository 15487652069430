'use client';

import { breakPoints } from '@/domains/style/models';
import type { BreakPointName } from '@/domains/style/models';

/**
 * 現在のブレークポイントをウィンドウサイズから取得する
 */
export default function getCurrentBreakpointFromWindow(): BreakPointName {
    if (window === undefined) {
        return 'xs';
    }

    const width = window.innerWidth;

    if (width >= Number.parseInt(breakPoints.lg, 10)) {
        return 'lg';
    }
    if (width >= Number.parseInt(breakPoints.md, 10)) {
        return 'md';
    }
    if (width >= Number.parseInt(breakPoints.sm, 10)) {
        return 'sm';
    }
    return 'xs';
}
